export default {
  methods: {
    getKeyEditor () {
      const parts = this.$route.path.split('/')

      if (!parts[1]) {
        this.$message.error(this.$t('commons.error-edit'))
        return
      }

      return parts[1]
    },
    saveEditorData (data) {
      const key = this.getKeyEditor()
      window.sessionStorage.setItem(key, JSON.stringify(data))
    },
    loadEditorData () {
      const key = this.getKeyEditor()
      const data = window.sessionStorage.getItem(key)

      if (data) {
        return JSON.parse(data)
      }

      throw new Error('No data loading')
    }
  }
}
