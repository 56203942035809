<template>
  <el-row type="flex" justify="center">
    <el-col :span="8">
      <el-form
        ref="fanpage"
        :model="fanpage"
        :rules="rulesValidate"
        class="fanpage-new">
        <el-form-item :label="$t('view.fanpage.new.name')" prop="name">
          <el-input v-model="fanpage.name" @keyup.enter.native="onSubmit" />
        </el-form-item>
        <el-form-item :label="$t('view.fanpage.new.username')" prop="username">
          <el-input v-model="fanpage.username" @keyup.enter.native="onSubmit" />
        </el-form-item>
        <el-form-item :label="$t('view.fanpage.new.facebookId')" prop="facebookId">
          <el-input v-model="fanpage.facebookId" @keyup.enter.native="onSubmit" />
        </el-form-item>
        <el-form-item :label="$t('view.fanpage.new.priority')" prop="location">
          <el-select
            v-model="fanpage.priority"
            :placeholder="$t('view.fanpage.new.priority')">
            <el-option
              v-for="item in priorities"
              :key="item.value"
              :label="item.text"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('view.fanpage.new.country')" prop="location">
          <el-select
            v-model="fanpage.location"
            :placeholder="$t('view.fanpage.new.select-country')">
            <el-option
              v-for="item in locations"
              :key="item.value"
              :label="item.text"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item align="right">
          <el-button @click="onCancel">{{ $t('commons.cancel') }}</el-button>
          <el-button type="primary" @click="onSubmit">{{ $t('commons.save') }}</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>
<script>
import EditorMixin from '@/mixins/editor'

export default {
  name: 'FanpageNew',
  data () {
    return {
      fanpage: {
        name: '',
        facebookId: '',
        priority: false,
        username: '',
        location: ''
      },
      rulesValidate: {
        name: [
          {
            required: true,
            message: this.$t('view.fanpage.new.required-field'),
            trigger: 'blur'
          }
        ],
        facebookId: [
          {
            required: true,
            message: this.$t('view.fanpage.new.required-field'),
            trigger: 'blur'
          }
        ],
        username: [
          {
            required: true,
            message: this.$t('view.fanpage.new.required-field'),
            trigger: 'blur'
          }
        ],
        location: [
          {
            required: true,
            message: this.$t('view.fanpage.new.required-field'),
            trigger: 'change '
          }
        ]
      },
      priorities: [
        {
          text: 'Baja',
          value: false
        },
        {
          text: 'Alta',
          value: true
        }
      ],
      locations: []
    }
  },
  methods: {
    loadData () {
      const vm = this

      if (vm.$route.params.idFanpage) {
        const data = vm.loadEditorData()
        if (data) {
          vm.$set(vm, 'fanpage', data)
        } else {
          vm.$message.error(vm.$t('commons.error-edit'))
          vm.$router.push({ name: 'FanpageList' })
        }
      }
    },
    async loadCountries () {
      const vm = this

      try {
        const response = await vm.$http.get('settings/city/')
        const places = response.body.map(country => {
          return {
            text: country.name,
            value: country.name.toLowerCase()
          }
        })

        vm.$set(vm, 'locations', this.locations.concat(places))
      } catch (err) {
        console.log(err)
      }
    },
    onCancel () {
      this.$router.push({ name: 'FanpageList' })
    },
    async onSubmit () {
      const vm = this
      const valid = await vm.$refs.fanpage.validate()
      console.log(valid)

      if (valid) {
        console.log('Submit!!!')
        if (vm.fanpage.id) {
          vm.editData()
        } else {
          vm.saveData()
        }
      } else {
        console.log('error submit!!')
        return false
      }
    },
    async saveData () {
      const vm = this

      try {
        const response = await vm.$http.post('facebook/fanpage', vm.fanpage)

        if (response) {
          vm.$message.success(vm.$t('view.fanpage.new.success-new'))
          vm.$router.push({ name: 'FanpageList' })
        }
      } catch (error) {
        console.log(error)
        vm.$message.error('Error al intentar crear un fanpage')
      }
    },
    async editData () {
      const vm = this
      try {
        const response = await vm.$http.post(
          `facebook/fanpage/${vm.fanpage.id}`,
          vm.fanpage
        )

        if (response) {
          vm.$message.success(vm.$t('view.fanpage.new.success-edit'))
          vm.$router.push({ name: 'FanpageList' })
        }
      } catch (error) {
        console.log(error)
        vm.$message.error('Error al intentar crear un fanpage')
      }
    }
  },
  mounted () {
    this.loadData()
    this.loadCountries()
  },
  mixins: [
    EditorMixin
  ]
}
</script>
<style lang="sass">
.fanpage-new
  background-color: #fff
  padding: 2rem 3rem
</style>
